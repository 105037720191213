import React from "react";

import { Layout, Menu, Dropdown, Row, Col } from "antd";
import { connect } from "react-redux";
import { UserOutlined } from "@ant-design/icons";
import { Link } from "@reach/router";

import jwt from "jsonwebtoken";

const { Header, Sider, Content } = Layout;

function NavigationBar({ config }) {
  const handleMenuClick = (e) => {
    console.log("click", e);
  };
  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="1">
        <UserOutlined />
        <Link to="/logout">Logout</Link>
      </Menu.Item>
    </Menu>
  );

  const getUserEmail = () => {
    const token = localStorage.getItem("token")
      ? localStorage.getItem("token")
      : undefined;
    if (token) {
      const decoded = jwt.decode(token);
      return decoded.email;
    } else return "";
  };

  return (
    <Header>
      <Layout>
        <Content>
          <Row justify="space-around" style={{ background: "#001529" }}>
            <Col span={8}>
              <div className="logo">
                <img src={config.logo} width="100%" height="100%" />
              </div>
              <Menu theme="dark" mode="horizontal" defaultSelectedKeys={["1"]}>
                <Menu.Item key="1">
                  <Link to={`/create/`}>Create</Link>
                </Menu.Item>
                <Menu.Item key="2">
                  <Link to={`/workflow/`}>Workflow</Link>
                </Menu.Item>
                <Menu.Item key="3">Settings</Menu.Item>
              </Menu>
            </Col>
            <Col span={8} offset={7} style={{ textAlign: "right" }}>
              <Dropdown.Button overlay={menu} icon={<UserOutlined />}>
                {getUserEmail()}
              </Dropdown.Button>
            </Col>
          </Row>
        </Content>
      </Layout>
    </Header>
  );
}

const mapStateToProps = (state) => ({
  config: state.config,
});

export default connect(mapStateToProps, null)(NavigationBar);

import React, { useEffect, useState } from "react";
import { Router } from "@reach/router";
import { Layout } from "antd";
import { connect } from "react-redux";

import * as actions from "./store/actions";

import { Modal, Menu, NavigationBar } from "./components";
import { ListItems, Entry, Login } from "./pages";
import PrivateRoute from "./utils/PrivateRoute";

import Logout from "./pages/Logout";
import Users from "./pages/Users";
import User from "./pages/User";
import MediaGallery from "./pages/MediaGallery";
import MediaItem from "./pages/MediaItem";


function AdminPanel({ config }) {
  const [openModal, setOpenModal] = useState(false)
  const [modalConfig, setModalConfig] = useState(undefined)
  useEffect(() => {

    // modal events
    window.addEventListener('open_modal', (e) => {
      setModalConfig(e.detail)
      setOpenModal(true)
    }, false)

    window.addEventListener('close_modal', () => {
      setModalConfig(undefined)
      setOpenModal(false)
    }, false)
  }, [])
  return (
    <Layout>
      <NavigationBar />
      <Layout>
        <Layout.Sider width={200} className="site-layout-background">
          <Router style={{ height: "100%" }}>
            <Menu config={config} default />
          </Router>
        </Layout.Sider>

        <Layout style={{ padding: "0 24px 24px", height: "100%" }}>
          <Modal open={openModal} modalConfig={modalConfig}/>
          <Router>
            <ListItems path="/create/content/:type" />
            <PrivateRoute as={Entry} path="/create/content/:type/new" />
            <PrivateRoute as={Entry} path="/create/content/:type/:id" />
            <PrivateRoute as={Users} path="/create/users" />
            <PrivateRoute as={User} path="/create/users/new" />
            <PrivateRoute as={User} path="/create/users/:id" />
            <PrivateRoute as={MediaGallery} path="/create/media" />
            <PrivateRoute as={MediaItem} path="/create/media/:id" />
            <ListItems path="/workflow/list/:type/:status" />
          </Router>
        </Layout>
      </Layout>
    </Layout>
  );
}

function App({ config, getConfig }) {
  useEffect(() => {
    async function init() {
      getConfig();
    }
    init();
  }, []);

  if (config) {
    return (
      <Router style={{ height: "100%" }}>
        <Login path="/login" />
        <Logout path="/logout" />
        <PrivateRoute as={AdminPanel} path="/*" config={config} />
      </Router>
    );
  } else return null;
}

const mapStateToProps = (state) => ({
  config: state.config,
});

const mapDispatchToProps = (dispatch) => ({
  getConfig: () => dispatch(actions.getConfig()),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);

import React from "react";

function Box(props) {
  const style = { backgroundColor: "white", padding: "2%", ...props.style };
  if (props.centered) {
    style.textAlign = "center";
  }
  if (props.transparent) {
    style.backgroundColor = "none";
  }
  return <div style={style}>{props.children}</div>;
}

export default Box;

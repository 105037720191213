import React from "react";
import { Select } from "antd";

const { Option } = Select;

function CMSSelect(props) {
  const onChange = (value) => {
    props.onChange(value);
  };

  const isKeyValue = typeof props.options[0] === "object";

  const createOptions = () => {
    if (!isKeyValue) {
      return props.options.map((option) => (
        <Option key={option} value={option}>
          {option}
        </Option>
      ));
    } else {
      return props.options.map((option) => (
        <Option key={option.value} value={option.value}>
          {option.label}
        </Option>
      ));
    }
  };

  if (props.onChange) {
    return (
      <Select value={props.value} disabled={props.disabled} onChange={onChange} style={props.style}>
        {createOptions()}
      </Select>
    );
  } else return props.value || "";
}

export default CMSSelect;

import React, { useEffect, useState, useMemo, Suspense, useReducer } from "react";
import moment from "moment";
import { Header, Form, Box, WorkflowSteps } from "../components";
import { Button, Spin } from "antd";
import { connect } from "react-redux";
import { SaveOutlined } from "@ant-design/icons";
import { ContentApi } from "../api";
import { convertUrlSearchParams, generateFormData, openNotification } from "../utils";

function ItemEntry(props) {
  const { config, type, id, isModal, defaultValues } = props

  const [content, setContent] = useState({});
  const [data, setData] = useState(undefined);
  const [pluginSettings, setPluginSettings] = useState({});
  const [step, setStep] = useState("draft");
  const [stepChange, setStepChange] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);

  const collection = config.collections.find(
    (collection) => collection.name === type
  );

  useEffect(() => {
    const fetchData = async () => {
      const content = await ContentApi.getContentEntry(type, id);
      setContent(content);
    };
    if (id) {
      try {
        fetchData();
      } catch (err) {
        openNotification(
          "error",
          `Can't fetch content`,
          "Check network settings"
        );
      }
    } else {
      makeData()
    }
  }, []);

  const makeData = () => {
    const search = props.location ? props.location.search : '';
    const urlQueryParams = new URLSearchParams(search);
    const queryParams = convertUrlSearchParams(urlQueryParams)
    const queryParamsMapped = Object.keys(queryParams).reduce((acc, key) => {
      acc[key] = parseInt(queryParams[key])
      return acc
    }, {});

    content.data = { ...content.data, ...queryParamsMapped }
    console.log('MAKE DATA --> ', content)
    const data = generateFormData(collection.fields, content);
    if (!id && defaultValues) {
      defaultValues.forEach((_, index) => {
        data[collection.fields[index].name] = defaultValues[index];
      });
    }
    if (!data.translations) data.translations = content.translations || {};
    setData(data);
  }

  useEffect(() => {
    makeData()
    setStep(content.status);
  }, [content]);

  const onFormFieldChange = (field, value) => {
    if (pluginSettings.lang && pluginSettings.lang !== pluginSettings.defaultLang) {
      // we have a language selected, so I update the translations object instead
      data.translations[pluginSettings.lang] = {
        ...data.translations[pluginSettings.lang],
        [field]: value,
      };
      setData(prevState => {
        return {
          ...prevState,
          ...data
        };
      });
    } else {
      setData(prevState => {
        return {
          ...prevState,
          [field]: value
        };
      });
    }
  };

  const onFormStatusChange = (status) => {
    setIsFormValid(status);
  };

  const headPluginCb = (newData, pluginSettings) => {
    if (newData) {
      setData({ ...data, ...newData });
      setIsFormValid(true)
    }
    if (pluginSettings) setPluginSettings(pluginSettings);
  };

  const headPluginError = (err) => {
    openNotification("error", "Error", err);
  };

  const saveEntry = async () => {
    try {
      if (!id) {
        // check if exists
        let alreadyExists = false;
        if (collection.uniqueField) {
          const existingContents = await ContentApi.getContentWithParam(
            type,
            collection.uniqueField,
            data[collection.uniqueField]
          );
          alreadyExists = existingContents.length > 0;
        }
        if (!alreadyExists) {
          await ContentApi.saveContentEntry(type, step, data);
        } else {
          throw new Error(
            "It seems that there is alredy an entry for this asin"
          );
        }
      } else await ContentApi.updateContentEntry(type, id, step, data);
      openNotification("success", `Entry saved`, "Your content has been saved");
      if (!isModal) window.history.back();
    } catch (err) {
      openNotification("error", `Can't save content`, err.toString());
    }
  };

  const onStepChange = (value) => {
    setStepChange(true);
    setStep(value);
  };

  const plugins = (collection.plugins || []).map((plugin) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const ComponentPlugin = useMemo(
      () => React.lazy(() => import(`../plugins/${plugin}`)),
      [plugin]
    );
    return ComponentPlugin;
  });

  if (!data && id) {
    return (
      <Box centered transparent>
        <Spin />
      </Box>
    );
  }
  return (
    <>
      <Header
        title={
          id
            ? `Edit ${collection.label_singular}: ${data[collection.identifier_field]
            }`
            : `Create new ${type}`
        }
      >
        {plugins.map((Plugin, index) => {
          return (
            <Suspense fallback="Loading..." key={index}>
              <Plugin
                data={data}
                id={id}
                collection={collection}
                config={config}
                callback={headPluginCb}
                onError={headPluginError}
              />
            </Suspense>
          );
        })}
        <Button
          icon={<SaveOutlined />}
          type="primary"
          onClick={saveEntry}
          disabled={!isFormValid && !stepChange}
        >
          Save
        </Button>
      </Header>
      {collection.publish_mode === "workflow" && (
        <WorkflowSteps currentStep={step} onChange={onStepChange} />
      )}
      <Box>
        <Form
          data={data}
          type={type}
          pluginSettings={pluginSettings}
          fields={collection.fields}
          onChange={onFormFieldChange}
          onFormStatusChange={onFormStatusChange}
        />
      </Box>
      {content && content.created_by && (
        <Box style={{ marginTop: "10px" }}>
          {content.created_by && <><b>Creato da:</b> {content.created_by?.name}{" "}
            {content.created_by?.surname} il{" "}
            {moment(content.created_date).format("DD/MM/YYYY")}
            <br /></>}
          {content.modified_by && (
            <>
              <b>Modificato da:</b> {content.modified_by?.name}{" "}
              {content.modified_by?.surname} il{" "}
              {moment(content.modified_date).format("DD/MM/YYYY")}
            </>
          )}
        </Box>
      )}
    </>
  );
}

const mapStateToProps = (state) => ({
  config: state.config,
});

export default connect(mapStateToProps, null)(ItemEntry);

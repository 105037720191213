import fetchAPI from "../fetchAPI";
const baseUrl = process.env.REACT_APP_API_URL;

const getContent = async (type, status, params) => {
  console.log(`Fetch ${type} in status ${status}`);
  let url = `${baseUrl}/content/${type}`;
  const urlParams = []
  if (params) urlParams.push(`params=${['id', ...params].join(',')}`)
  if (status) urlParams.push(`status=${status}`)
  url = `${url}?${urlParams.join('&')}`
  const res = await fetchAPI(url);
  const data = await res.json();
  return data;
};

const getContentWithParam = async (type, field, value) => {
  const url = `${baseUrl}/content/${type}?field=${field}&values=${value}`;
  const res = await fetchAPI(url);
  const data = await res.json();
  return data;
};

const getContentEntry = async (type, id) => {
  console.log(`Fetch ${id}`);
  const url = `${baseUrl}/content/${type}/${id}`;
  const res = await fetchAPI(url);
  const data = await res.json();
  return data;
};

const saveContentEntry = async (type, status, body) => {
  let url = `${baseUrl}/content/${type}`;
  if (status) url = `${url}?status=${status}`;
  const res = await fetchAPI(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  });
  const data = await res.json();

  return data;
};

const deleteContentEntry = async (type, id) => {
  const url = `${baseUrl}/content/${type}/${id}`;
  const res = await fetchAPI(url, {
    method: "DELETE",
  });
  const data = await res.json();

  return data;
};

const updateContentEntry = async (type, id, status, body) => {
  const url = `${baseUrl}/content/${type}/${id}?status=${status}`;
  const res = await fetchAPI(url, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  });
  const data = await res.json();
  return data;
};

export {
  getContent,
  getContentEntry,
  saveContentEntry,
  deleteContentEntry,
  updateContentEntry,
  getContentWithParam,
};

import YAML from "yaml";
import { notification } from "antd";
import { orderBy } from "lodash";
import jwt from "jsonwebtoken";

const configFile = `/${process.env.REACT_APP_CONFIG_FILE}`;

const asyncForEach = async (array, callback) => {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
};

const getConfigYAML = () =>
  new Promise((resolve, reject) => {
    fetch(configFile)
      .then((file) => file.text())
      .then((text) => {
        resolve(YAML.parse(text));
      })
      .catch((err) => {
        console.log(err);
        reject(err);
      });
  });

const generateFormData = (fields, content) => {
  const data = fields.reduce((acc, field) => {
    return {
      ...acc,
      [field.name]: (content.data && content.data[field.name]) || undefined,
    };
  }, {});
  return data;
};

const generateDynamicOptionsPanelData = (fields, content, order = false) => {
  const data = Object.keys(content).reduce((acc, entry) => {
    acc[entry] = {key: entry, ...generateFormData(fields, { data: content[entry] })};
    return acc;
  }, {});
  return order ? orderBy(data, 'order') : data;
};

const openNotification = (type, title, description) => {
  notification[type]({
    message: title,
    description,
  });
};

const checkAuthentication = () => {
  const token = localStorage.getItem("token")
    ? localStorage.getItem("token")
    : undefined;
  if (token) return jwt.decode(token);
  else return;
};

const getToken = () => {
  return localStorage.getItem("token");
};

const removeAuthentication = () => {
  localStorage.removeItem("token");
};

const convertUrlSearchParams = (entries) => {
  const result = {}
  for (const [key, value] of entries) {
    result[key] = value;
  }
  return result;
}

export {
  asyncForEach,
  getConfigYAML,
  generateFormData,
  generateDynamicOptionsPanelData,
  openNotification,
  checkAuthentication,
  removeAuthentication,
  getToken,
  convertUrlSearchParams
};

import React, { useEffect, useState } from "react";
import { message, Button, List, Avatar } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { MediaApi } from "../api";


const cloudinaryWidget = props => window.cloudinary.createUploadWidget(
  {
    cloudName: process.env.REACT_APP_CLOUDINARY_CLOUD_NAME,
    uploadPreset: process.env.REACT_APP_CLOUDINARY_PRESET,
    // cropping: true, //add a cropping step
    // showAdvancedOptions: true,  //add advanced options (public_id and tag)
    sources: ["local", "url"], // restrict the upload sources to URL and local files
    // multiple: false,  //restrict upload to a single file
    // thumbnails: "#upload_thumbs",
    folder: props.folder, //upload files to the specified folder
    // tags: ["users", "profile"], //add the given tags to the uploaded files
    // context: {alt: "user_uploaded"}, //add the given context data to the uploaded files
    // clientAllowedFormats: ["images"], //restrict uploading to image files only
    // maxImageFileSize: 2000000,  //restrict file size to less than 2MB
    // maxImageWidth: 2000, //Scales the image down to a width of 2000 pixels before uploading
  },
  (error, result) => {
    if (!error && result && result.event === "success") {
      props.onChange(result.info)
    } else if (error) {
      message.error(`File upload error`);
      console.log(error)
    }
  }
);


function CloudinaryUpload({ value, onChange, disabled, allowedFiles = 1 }) {
  const [fileList, setFileList] = useState([]);
  const folder = "zeta_cms"

  useEffect(() => {
    const defaultFileList = value && Array.isArray(value) ? value : [];
    setFileList(defaultFileList);
  }, [value]);


  const onChangeFiles = async (info) => {
    console.log("Done! Here is the image info --> ", info);
    message.success(`File uploaded successfully`);


    const newFile = {
      asset_id: info.asset_id,
      thumbnail_url: info.thumbnail_url,
      original_filename: info.original_filename,
      secure_url: info.secure_url,
      public_id: info.public_id
    }
    const fileUploaded = await MediaApi.onFileUploaded({
      key: info.asset_id,
      data: newFile,
    });

    newFile.id = fileUploaded[0]

    const newList = [...fileList, newFile]
    setFileList(newList)
    onChange(newList)
  }

  const deleteFile = (file) => {
    MediaApi.deleteObject({
      key: file.asset_id,
      id: file.id,
      provider: 'cloudinary',
    });
    const newList = fileList.filter(f => f.id !== file.id)
    setFileList(newList)
  }


  const widgetInstance = cloudinaryWidget({ onChange: onChangeFiles, folder })


  if (!process.env.REACT_APP_CLOUDINARY_CLOUD_NAME || !process.env.REACT_APP_CLOUDINARY_PRESET) {
    return <span>Please add Cloudinary config!</span>
  }
  return <>
    <Button
      disabled={disabled}
      id="upload_widget" onClick={widgetInstance.open}>
      Carica immagine
    </Button>

    <List
      itemLayout="horizontal"
      dataSource={fileList}
      renderItem={item => (
        <List.Item actions={[<Button icon={<DeleteOutlined />} onClick={() => deleteFile(item)}></Button>]}>
          <List.Item.Meta
            avatar={<Avatar src={item.thumbnail_url} />}
            title={<a href={item.secure_url} target="_blank" rel="noopener noreferrer">{item.original_filename}</a>}
          />
        </List.Item>
      )}
    />
  </>

}

export default CloudinaryUpload;

import { getToken } from "../utils";

const headers = {
  Authorization: getToken(),
};

const fetchAPI = (input, init) => {
  if (!init) {
    init = { headers };
  } else {
    init.headers = init.headers ? { ...init.headers, ...headers } : headers;
  }

  return fetch(input, init);
};

export default fetchAPI;

import React, { useEffect, useState } from "react";
import { Link, navigate } from "@reach/router";
import { Button, Row, Col } from "antd";
import { connect } from "react-redux";

import { DeleteOutlined, SaveOutlined } from "@ant-design/icons";

import { Header, Box, Form } from "../components";
import { MediaApi, ContentApi } from "../api";
import { openNotification } from "../utils";

function MediaItem({ config, id }) {
  const [itemSrc, setItemSrc] = useState('');
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(undefined);
  const [isFormValid, setIsFormValid] = useState(false);
  const [opLoading, setOpLoading] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoading(true);
    const content = await MediaApi.getData(id);
    const res = content[0]
    const imageUrl = `${process.env.REACT_APP_API_URL}/media/${res.key}?w=480&h=400&format=webp`;
    setData({key: res.key, id: res.id, ...res.data})
    setItemSrc(imageUrl);

    setLoading(false);
  };

  const deleteMedia = async () => {
    setOpLoading(true);
    try {
      console.log(data)
      await MediaApi.deleteObject(data);
      openNotification(
        "success",
        `Entry deleted`,
        "Your content has been deleted"
      );
      navigate(`/create/media`);
    } catch (err) {
      openNotification(
        "error",
        `Can't delete content`,
        "I was unable to delete your content"
      );
    }
    setOpLoading(false);
  };

  const saveMedia = async () => {
    try {
      await MediaApi.updateData(id, data);
      openNotification("success", `Media saved`, "Your media has been saved");
      navigate(`/create/media`);
    } catch (err) {
      openNotification(
        "error",
        `Can't save content`,
        "I was unable to save your content"
      );
    }
  };

  const onFormFieldChange = (field, value) => {
    setData({
      ...data,
      [field]: value,
    });
  };

  const onFormStatusChange = (status) => {
    setIsFormValid(status);
  };

  const collection = config.media;
  if (!data || loading) {
    return null
  }
  return (
    <>
      <Header title="Media Item">
        <Button
          icon={<SaveOutlined />}
          type="primary"
          onClick={saveMedia}
          disabled={!isFormValid}
        >
          Save
        </Button>
        <Button
          danger
          icon={<DeleteOutlined />}
          loading={opLoading}
          onClick={deleteMedia}
        >
          Delete
        </Button>
      </Header>

      <Box>
        <Row>
          <Col span={8}>
            <img
              alt={data.name}
              src={itemSrc}
              style={{
                height: "340px",
                width: "340px",
                objectFit: "cover",
                border: "2px solid #f0f0f0",
              }}
            />
          </Col>
          <Col span={16}>
            <Form
              data={data}
              fields={collection.fields}
              onChange={onFormFieldChange}
              onFormStatusChange={onFormStatusChange}
            />
          </Col>
        </Row>
      </Box>
    </>
  );
}

const mapStateToProps = (state) => ({
  config: state.config,
});

export default connect(mapStateToProps, null)(MediaItem);

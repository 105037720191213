import React from "react";
import { InputNumber } from "antd";

function CMSInputNumber(props) {
  const max = props.max && parseInt(props.max);
  const min = props.min && parseInt(props.min);

  const onChange = (value) => {
    props.onChange(value);
  };

  if (props.onChange) {
    return (
      <InputNumber
        disabled={props.disabled}
        max={max}
        min={min}
        value={props.value}
        onChange={onChange}
      />
    );
  } else return props.value || "";
}

export default CMSInputNumber;

import React, { useEffect, useState } from "react";
import { Link, navigate } from "@reach/router";
import { Button } from "antd";
import { connect } from "react-redux";
import moment from "moment";

import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";

import { Header, MediaCard } from "../components";
import { MediaApi } from "../api";
import { asyncForEach, openNotification } from "../utils";

function ListItems({ type, config }) {
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [opLoading, setOpLoading] = useState(false);
  const [selectedMedia, setSelectedMedia] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoading(true);
    const data = await MediaApi.listItems("images");
    const items = data.map((media) => {
      const imageUrl = config.upload_service === 'cloudinary' ? media.data.thumbnail_url : `${process.env.REACT_APP_API_URL}/media/${media.key}?w=480&h=400&format=webp`;
      return {
        ...media,
        key: media.key,
        size: media.data.bytes,
        src: imageUrl,
      };
    });
    setItems(items);
    console.log(items);
    setLoading(false);
  };

  const deleteMedia = async () => {
    setOpLoading(true);
    try {
      await asyncForEach(selectedMedia, async (media) => {
        await MediaApi.deleteObject(media);
      });
      openNotification(
        "success",
        `Entry deleted`,
        "Your content has been deleted"
      );
    } catch (err) {
      openNotification(
        "error",
        `Can't delete content`,
        "I was unable to delete your content"
      );
    }
    await fetchData();
    setOpLoading(false);
  };

  const onSelectMedia = (currentMedia, selected) => {
    let newSelectedMedia;
    if (!selected) {
      newSelectedMedia = selectedMedia.filter((media) => media.key !== currentMedia.key);
    } else {
      newSelectedMedia = [...selectedMedia, currentMedia];
    }
    setSelectedMedia(newSelectedMedia);
  };

  return (
    <>
      <Header title="Media Library" itemsCount={items.length} type={type}>
        <Button
          danger
          icon={<DeleteOutlined />}
          loading={opLoading}
          onClick={deleteMedia}
        >
          Delete
        </Button>
        {/* <Button
          icon={<PlusOutlined />}
          type="primary"
          onClick={() => navigate(`/create/content/${type}/new`)}
        >
          Create new
        </Button> */}
      </Header>
      <div
        style={{
          display: "flex",
          flex: 1,
          flexWrap: "wrap",
          justifyContent: "space-around",
        }}
      >
        {items.map((item) => (
          <MediaCard media={item} onSelect={onSelectMedia} key={item.key} />
        ))}
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  config: state.config,
});

export default connect(mapStateToProps, null)(ListItems);

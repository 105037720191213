import React from "react";
import { PageHeader } from "antd";

function Header({ title, type, itemsCount, children }) {
  return (
    <PageHeader
      style={{ marginTop: "20px" }}
      title={title}
      subTitle={itemsCount &&`You have ${itemsCount} ${type || 'items'}`}
      extra={children}
    ></PageHeader>
  );
}

export default Header;

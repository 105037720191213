import React from "react";
import { Modal } from "antd";
import { Entry } from "../pages";

const components = {
  Entry: Entry,
};

function ModalWindow(props) {
  const { open, modalConfig } = props;

  const handleClose = () => {
    const event = new Event("close_modal");
    window.dispatchEvent(event);
  };

  if (modalConfig) {
    const ChildrenComponent = components[modalConfig.component];

    return (
      <Modal visible={open} footer={null} width="70%" onCancel={handleClose}>
        <ChildrenComponent {...modalConfig.props} isModal={true} />
      </Modal>
    );
  } else
    return (
      <Modal visible={open} footer={null} width="70%" onCancel={handleClose}>
        No Children component
      </Modal>
    );
}

export default ModalWindow;

import React from "react";
import { DatePicker } from "antd";
import moment from "moment";

const dateFormat = "DD/MM/YYYY";

function CMSDatePicker(props) {
  function onChange(date, dateString) {
    props.onChange(date);
  }

  if (props.onChange) {
    return (
      <DatePicker
        disabled={props.disabled}
        format={dateFormat}
        value={props.value && moment(props.value)}
        onChange={onChange}
      />
    );
  } else return moment(props.value).format(dateFormat);
}

export default CMSDatePicker;

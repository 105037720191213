import React from "react";
import Switch from "./Switch";
import Input from "./Input";
import Select from "./Select";
import TextArea from "./TextArea";
import DatePicker from "./DatePicker";
import MarkdownEditor from "./Markdown";
import Upload from "./Upload";
import CloudinaryUpload from "./CloudinaryUpload";
import MultiSelect from "./MultiSelect";
import InputNumber from "./InputNumber";
import DynamicOptions from "./DynamicOptions";

const availableComponents = {
  boolean: Switch,
  string: Input,
  select: Select,
  text: TextArea,
  datetime: DatePicker,
  markdown: MarkdownEditor,
  image: Upload,
  list: MultiSelect,
  number: InputNumber,
  relation: MultiSelect,
  options: DynamicOptions
};

function Field({ field, data, relatedFieldValue, config, disabled, pluginSettings, onChange }) {
  const Component = field.widget === 'image' && config.upload_service === 'cloudinary' ? CloudinaryUpload : availableComponents[field.widget];

  if (Component) {
    return (
      <Component
        {...field}
        disabled={disabled}
        pluginSettings={pluginSettings}
        value={data}
        relatedValue={relatedFieldValue}
        onChange={onChange ? (value) => onChange(field.name, value) : undefined}
      />
    );
  } else return <div>Undefined {field.widget} type</div>;
}

export { Field };

import React, { useEffect, useState } from "react";
import MDEditor from "@uiw/react-md-editor";

function Markdown(props) {
  const [value, setValue] = useState(props.value);

  useEffect(() => {
    if (props.value) {
      setValue(props.value);
    }
  }, [props.value]);

  const onChange = (newValue) => {
    if (value !== newValue && newValue) {
      props.onChange(newValue);
    }
  };

  return props.onChange ? <MDEditor value={value} onChange={onChange} /> : (props.value || '');
}
export default Markdown;

import React from "react";
import { navigate } from "@reach/router";
import { Button, Form, Input } from "antd";

import { UserOutlined, LockOutlined } from "@ant-design/icons";

import { UserApi } from "../api";

function Login() {
  const loginUser = (email, password) => {
    try {
      UserApi.login({
        email,
        password,
      })
        .then((data) => {
          localStorage.setItem("token", data.accessToken);
          navigate("/");
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (err) {
      console.log("NO LOGIN", err);
    }
  };

  const onFinish = (values) => {
    console.log("Received values of form: ", values);
    loginUser(values.username, values.password);
  };

  return (
    <div className="login-bg">
      <div className="login-form">
        <Form
          name="normal_login"
          className="login-fields"
          initialValues={{ remember: true }}
          onFinish={onFinish}
        >
          <Form.Item
            name="username"
            rules={[{ required: true, message: "Please input your Username!" }]}
          >
            <Input
              prefix={<UserOutlined className="site-form-item-icon" />}
              placeholder="Username"
            />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[{ required: true, message: "Please input your Password!" }]}
          >
            <Input
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              placeholder="Password"
            />
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button"
            >
              Log in
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}

export default Login;

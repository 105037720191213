import { GET_CONFIG_SUCCESS } from "../constants/ActionTypes";

const initialState = null;

export default function config(state = initialState, action) {
  switch (action.type) {
    case GET_CONFIG_SUCCESS:
      return action.data;

    default:
      return state;
  }
}

import fetchAPI from "../fetchAPI";
const baseUrl = process.env.REACT_APP_API_URL;

const login = async (body) => {
  const url = `${baseUrl}/users/login`;
  const res = await fetchAPI(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  });
  const data = await res.json();

  return data;
};

const getUsers = async () => {
  const url = `${baseUrl}/users`;
  const res = await fetchAPI(url);
  const data = await res.json();
  return data;
};

const getUser = async (id) => {
  const url = `${baseUrl}/users/${id}`;
  const res = await fetchAPI(url);
  const data = await res.json();
  return data;
};

const deleteUser = async (id) => {
  const url = `${baseUrl}/users/${id}`;
  const res = await fetchAPI(url, {
    method: "DELETE",
  });
  const data = await res.json();

  return data;
};

const saveUser = async (body) => {
  const url = `${baseUrl}/users`;
  const res = await fetchAPI(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  });
  const data = await res.json();

  return data;
};

const updateUser = async (id, body) => {
  const url = `${baseUrl}/users/${id}`;
  const res = await fetchAPI(url, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  });
  const data = await res.json();

  return data;
};

export { login, getUsers, getUser, deleteUser, saveUser, updateUser };

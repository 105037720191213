import React from "react";
import { Steps } from "antd";

function getKeyByValue(object, value) {
  return Object.keys(object).find((key) => object[key] === value);
}

function WorkflowSteps({ currentStep, onChange }) {
  const stepsMap = {
    0: "draft",
    1: "review",
    2: "published",
    3: "trash"
  };
  const onChangeValue = (value) => {
    onChange(stepsMap[value]);
  };

  return (
    <div style={{ margin: "40px" }}>
      <Steps
        current={parseInt(getKeyByValue(stepsMap, currentStep))}
        onChange={onChangeValue}
      >
        <Steps.Step title="Bozza" />
        <Steps.Step title="Revisione" />
        <Steps.Step title="Pubblicato" />
        <Steps.Step title="Cestino" />
      </Steps>
    </div>
  );
}

export default WorkflowSteps;

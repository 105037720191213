import * as types from "../constants/ActionTypes";
import { getConfigYAML } from "../../utils";

export const getConfig = () => {
  return (dispatch, _getState) => {
    getConfigYAML()
      .then((config) => {
        dispatch(getConfigSuccess(config));
      })
      .catch((err) => {
        dispatch(getConfigFailure(err));
      });
  };
};

const getConfigSuccess = (data) => ({
  type: types.GET_CONFIG_SUCCESS,
  data,
});
const getConfigFailure = (err) => ({ type: types.GET_CONFIG_FAILURE, err });

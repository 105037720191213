import React, { Component } from "react";
import { Table } from "antd";

class TableDemo extends Component {
  state = {
    selectedRowKeys: [], // Check here to configure the default column
    loading: false,
    rows: [],
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.items !== this.props.items) {
      this.setState({
        rows: this.props.items.map((item) => ({
          key: item.id,
          ...item.data,
        })),
      });
    }
  }

  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
    if (this.props.onSelectRows) {
      this.props.onSelectRows(selectedRowKeys);
    }
  };

  render() {
    const { loading, selectedRowKeys } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };
    const hasSelected = selectedRowKeys.length > 0;
    return (
      <div>
        <div style={{ marginBottom: 16 }}>
          <span style={{ marginLeft: 8 }}>
            {hasSelected ? `Selected ${selectedRowKeys.length} items` : ""}
          </span>
        </div>
        <Table
          loading={this.props.loading}
          rowSelection={rowSelection}
          columns={this.props.columns}
          dataSource={this.state.rows}
        />
      </div>
    );
  }
}

export default TableDemo;

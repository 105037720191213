import React from "react";
import {
  UserOutlined,
  PicRightOutlined,
  FileImageOutlined,
  CopyOutlined,
  HighlightOutlined,
  CheckCircleOutlined,
  DeleteOutlined
} from "@ant-design/icons";
import { Link } from "@reach/router";
import { useLocation } from "@reach/router";

import { Menu } from "antd";

const { SubMenu } = Menu;

function CMSMenu(props) {
  const location = useLocation();
  const path = location.pathname.split("/").filter((p) => p.length > 0);

  if (path[0] === "create" || path.length === 0) {
    return (
      <Menu
        mode="inline"
        defaultSelectedKeys={[path[1]]}
        defaultOpenKeys={[path[0]]}
        style={{ height: "100%", borderRight: 0 }}
      >
        <SubMenu
          key="content"
          title={
            <span>
              <PicRightOutlined />
              Content
            </span>
          }
        >
          {props.config.collections.map((collection) => {
            return (
              <Menu.Item key={collection.name}>
                <Link to={`/create/content/${collection.name}`}>
                  {collection.label}
                </Link>
              </Menu.Item>
            );
          })}
        </SubMenu>
        <SubMenu
          key="users"
          title={
            <span>
              <UserOutlined />
              Users
            </span>
          }
        >
          <Menu.Item key="5">
            <Link to="/create/users">Users</Link>
          </Menu.Item>
          <Menu.Item key="6">
            <Link to="/create/roles">Roles</Link>
          </Menu.Item>
        </SubMenu>
        <SubMenu
          key="media"
          title={
            <span>
              <FileImageOutlined />
              Media
            </span>
          }
        >
          <Menu.Item key="7">
            <Link to="/create/media">Images</Link>
          </Menu.Item>
        </SubMenu>
      </Menu>
    );
  }

  if (path[0] === "workflow") {
    return (
      <Menu
        mode="inline"
        defaultSelectedKeys={[path[1]]}
        defaultOpenKeys={[path[0]]}
        style={{ height: "100%", borderRight: 0 }}
      >
        <Menu.Item key={0}>
          <Link to={`/workflow/list/osp/draft`}>
            <span>
              <CopyOutlined />
              Bozze
            </span>
          </Link>
        </Menu.Item>
        <Menu.Item key={1}>
          <Link to={`/workflow/list/osp/review`}>
            <span>
              <HighlightOutlined />
              Revisione
            </span>
          </Link>
        </Menu.Item>
        <Menu.Item key={2}>
          <Link to={`/workflow/list/osp/published`}>
            <span>
              <CheckCircleOutlined />
              Pubblicati
            </span>
          </Link>
        </Menu.Item>
        <Menu.Item key={3}>
          <Link to={`/workflow/list/osp/trash`}>
            <span>
              <DeleteOutlined />
              Cestino
            </span>
          </Link>
        </Menu.Item>
      </Menu>
    );
  }

  return null;
}

export default CMSMenu;

import React, { useEffect, useState } from "react";
import { Header, Form, Box } from "../components";
import { Button, Spin } from "antd";
import { connect } from "react-redux";
import { SaveOutlined } from "@ant-design/icons";
import { ContentApi, UserApi } from "../api";
import { generateFormData, openNotification } from "../utils";
import { navigate } from "@reach/router";

function UserEntry({ config, type, id }) {
  const [content, setContent] = useState({});
  const [data, setData] = useState(undefined);
  const [isFormValid, setIsFormValid] = useState(false);

  const collection = config.users;
  console.log("cooll", collection);

  useEffect(() => {
    const fetchData = async () => {
      const entry = await UserApi.getUser(id);
      setContent({
        id: entry.id,
        data: entry,
      });
    };
    if (id) {
      fetchData();
    }
  }, []);

  useEffect(() => {
      console.log(collection.fields)
    const data = generateFormData(collection.fields, content);
    setData(data);
  }, [content]);

  const onFormFieldChange = (field, value) => {
    setData({
      ...data,
      [field]: value,
    });
  };

  const onFormStatusChange = (status) => {
    setIsFormValid(status);
  };

  const saveEntry = async () => {
    try {
      if (!id) {
        await UserApi.saveUser(data);
      } else await UserApi.updateUser(id, data);
      openNotification("success", `Entry saved`, "Your content has been saved");
      navigate(`/users`);
    } catch (err) {
      openNotification(
        "error",
        `Can't save content`,
        "I was unable to save your content"
      );
    }
  };

  if (data || !id) {
    return (
      <>
        <Header
          title={
            id
              ? `Edit ${collection.label_singular}: ${
                  data[collection.identifier_field]
                }`
              : `Create new ${type}`
          }
        >
          <Button
            icon={<SaveOutlined />}
            type="primary"
            onClick={saveEntry}
            disabled={!isFormValid}
          >
            Save
          </Button>
        </Header>
        <Box>
          <Form
            data={data}
            type={type}
            fields={collection.fields}
            onChange={onFormFieldChange}
            onFormStatusChange={onFormStatusChange}
          />
        </Box>
      </>
    );
  } else
    return (
      <Box centered transparent>
        <Spin />
      </Box>
    );
}

const mapStateToProps = (state) => ({
  config: state.config,
});

export default connect(mapStateToProps, null)(UserEntry);

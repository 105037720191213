import React, { useState, useEffect } from "react";
import { Select, Tag, Button } from "antd";
import { ContentApi } from "../api";
import fetchAPI from "../api/fetchAPI"

const { Option } = Select;


const baseUrl = process.env.REACT_APP_API_URL;

const openModalForValue = async (value, fieldProps) => {
  const modalContent = await ContentApi.getContentWithParam(
    fieldProps.collection,
    fieldProps.valueField,
    value
  );
  const event = new CustomEvent("open_modal", {
    detail: {
      component: "Entry",
      props: { type: fieldProps.collection, id: modalContent[0].id },
    },
  });
  window.dispatchEvent(event);
};

function tagRender(tagProps, fieldProps) {
  const { label, value, closable, onClose } = tagProps;

  return (
    <Tag closable={closable} onClose={onClose} style={{ marginRight: 3 }}>
      <Button
        onClick={() => openModalForValue(value, fieldProps)}
        size="small"
        type="link"
      >
        {label}
      </Button>
    </Tag>
  );
}

function MultiSelect(props) {
  const [options, setOptions] = useState([]);

  async function populateRelation() {
    const { widget, collection, api, valueField, displayFields } = props;
    if (widget === "relation") {
      if (!collection && !api) throw new Error('Missing collection and api in the relation field')
      let relatedOptions = []
      if (collection) {
        const relatedData = await ContentApi.getContent(collection);
        relatedOptions = relatedData.map((entry) => ({
          value: valueField === "id" ? entry.id : entry.data[valueField],
          label: displayFields
            .map((displayField) => entry.data[displayField])
            .join(" - "),
        }));

      }
      if (api) {
        if (props.relatedField && !props.relatedValue) return
        let url = `${baseUrl}${api}`;
        if (props.relatedValue) {
          url = url.replace('{{relatedFieldValue}}', props.relatedValue)
        }
        const res = await fetchAPI(url);
        const relatedData = await res.json();
        relatedOptions = relatedData.map((entry) => ({
          value: entry[valueField],
          label: displayFields
            .map((displayField) => entry[displayField])
            .join(" - "),
        }));
      }
      setOptions(relatedOptions);
    } else setOptions(props.options);
  }

  useEffect(() => {
    populateRelation();
  }, []);

  useEffect(() => {
    populateRelation()
  }, [props.relatedValue])

  if (props.onChange) {
    return (
      <Select
        disabled={props.disabled}
        showSearch={true}
        mode={props.single ? "" : "multiple"}
        placeholder={props.noEntriesHint || "Please select"}
        tagRender={
          props.editInModal ? (tagProps) => tagRender(tagProps, props) : null
        }
        filterOption={(input, option) => {
          return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        }
        value={props.value}
        onChange={props.onChange}
      >
        {options
          ? options.map((option) => (
            <Option key={option.value} value={option.value}>
              {option.label}
            </Option>
          ))
          : null}
      </Select>
    );
  } else return props.value || null;
}

export default MultiSelect;

import React from "react";
import { Card, Checkbox } from "antd";
import { navigate } from "@reach/router"

import { EditOutlined } from "@ant-design/icons";

function MediaCard({ media, onSelect }) {
  if (!media) return null;
  return (
    <Card
      style={{ width: 240, margin: "10px" }}
      bodyStyle={{ padding: 0 }}
      cover={
        <div>
          <img
            alt="Immagine"
            src={media.src}
            style={{ height: "200px", width: "240px", objectFit: "cover" }}
          />
        </div>
      }
      actions={[
        <Checkbox onChange={(e) => onSelect(media, e.target.checked)} />,
        <EditOutlined key="edit" onClick={() => navigate(`/create/media/${media.id}`)} />,
      ]}
    ></Card>
  );
}

export default MediaCard;

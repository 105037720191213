import React, { useState } from "react";
import { Form, Tooltip } from "antd";
import { connect } from "react-redux";
import { QuestionCircleOutlined } from "@ant-design/icons";

import { Field } from "../fields";

const layout = (labelCol, wrapperCol) => ({
  labelCol: { span: labelCol },
  wrapperCol: { span: wrapperCol },
});

function CMSForm({
  config,
  data,
  fields,
  onChange,
  onFormStatusChange,
  pluginSettings = {},
  labelCol = 8,
  wrapperCol = 16,
}) {
  const [formTest, setFormTest] = useState({});

  const checkFields = () => {
    const formTestResult = fields.reduce((acc, field) => {
      const value = data[field.name];
      const test = {
        validateStatus: "success",
      };
      if (field.required && !value) {
        test.validateStatus = "error";
        test.help = `${field.label} is required`;
      }
      if (field.pattern && test.validateStatus !== "error") {
        const regex = new RegExp(field.pattern[0]);
        if (!regex.test(value)) {
          test.validateStatus = "error";
          test.help = `${field.label} ${field.pattern[1]}`;
        }
      }
      acc[field.name] = test;
      return acc;
    }, {});
    setFormTest(formTestResult);
    const isFormValid = Object.values(formTestResult).reduce((acc, test) => {
      if (test.validateStatus === "error") return false;
      else return acc;
    }, true);
    return isFormValid;
  };

  const changeField = (field, value) => {
    const fieldDescriptor = fields.find(panelField => panelField.name === field)

    if (fieldDescriptor.clearChildrenOnChange) {
      const fieldKey = fieldDescriptor.name

      const found = fields.filter(ff => ff.visible && ff.visible.includes(fieldKey))
      found.forEach(foundField => {
        console.log('Azzero -> ', foundField.name)
        onChange(foundField.name, undefined)
      })
    }
    onChange(field, value)
    const isFormValid = checkFields();

    if (onFormStatusChange) onFormStatusChange(isFormValid);
  };

  if (data) {
    if (pluginSettings.lang) {
      if (data.translations && !data.translations[pluginSettings.lang]) {
        const translatedData = { ...data }
        delete translatedData.translations
        data.translations[pluginSettings.lang] = translatedData;
      }
    }

    return (
      <>
        <Form {...layout(labelCol, wrapperCol)}>
          {fields.map((field) => {
            if (field.widget === "hidden") return null;
            let showField = true
            if (field.visible) {
              const formula = field.visible.replaceAll('$', 'data.')
              // eslint-disable-next-line no-eval
              showField = eval(formula)
            }
            if (!showField) return null
            if (field.translate === undefined) field.translate = true
            return (
              <Form.Item
                key={field.name}
                name={field.name}
                validateStatus={
                  formTest[field.name] && formTest[field.name].validateStatus
                }
                help={formTest[field.name] && formTest[field.name].help}
                label={
                  <span>
                    {field.label}
                    {field.hint && (
                      <Tooltip title={field.hint}>
                        <QuestionCircleOutlined
                          style={{ marginLeft: "4px", marginRight: "2px" }}
                        />
                      </Tooltip>
                    )}
                  </span>
                }
              >
                <Field
                  config={config}
                  data={
                    pluginSettings.lang &&
                      pluginSettings.defaultLang !== pluginSettings.lang &&
                      data.translations
                      ? data.translations[pluginSettings.lang][field.name]
                      : data[field.name]
                  }
                  field={field}
                  relatedFieldValue={data[field.relatedField]}
                  pluginSettings={pluginSettings}
                  disabled={pluginSettings.defaultLang !== pluginSettings.lang && !field.translate}
                  key={field.name}
                  onChange={changeField}
                />
              </Form.Item>
            );
          })}
        </Form>
      </>
    );
  } else return null;
}


const mapStateToProps = (state) => ({
  config: state.config,
});

export default connect(mapStateToProps, null)(CMSForm);

import React from "react";
import { Input } from "antd";

function CMSInput(props) {
  const onChange = (e) => {
    props.onChange(e.target.value);
  };
  if (props.onChange) {
    return (
      <Input
        value={props.value}
        disabled={props.disabled}
        onChange={onChange}
      />
    );
  } else return props.value || "";
}

export default CMSInput;

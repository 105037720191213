import React, { useEffect, useState } from "react";
import { Collapse, Button } from "antd";
import { DeliveredProcedureOutlined, UpOutlined, DownOutlined, DeleteOutlined } from "@ant-design/icons";
import { v4 as uuidv4 } from 'uuid';

import { ContentApi } from "../api";
import { Form } from "../components";
import { generateDynamicOptionsPanelData } from "../utils";

const { Panel } = Collapse;

const openModalForValue = async (value, fieldProps) => {
  const modalContent = await ContentApi.getContentWithParam(
    fieldProps.collection,
    fieldProps.valueField,
    value
  );
  const event = new CustomEvent("open_modal", {
    detail: {
      component: "Entry",
      props: {
        type: fieldProps.collection,
        id: modalContent[0] && modalContent[0].id,
        defaultValues: [value],
      },
    },
  });
  window.dispatchEvent(event);
};

function CMSDynamicOptions(props) {
  const [data, setData] = useState(undefined);

  useEffect(() => {
    if (props.panel && props.value) {
      const currentData = generateDynamicOptionsPanelData(props.panel, props.value, props.orderBy && !data);
      setData(currentData);
    }
  }, [props.value, props.panel]);

  const onFormFieldChange = (entry, field, value) => {
    setData(prevState => {
      const newData = { ...prevState };
      newData[entry] = {
        ...newData[entry],
        [field]: value,
      };
      props.onChange(newData);
      return newData
    });
  };

  const addItem = () => {
    const newId = uuidv4()
    const newData = {
      ...data, [newId]: {
      }
    }
    setData(newData)
    props.onChange(newData)
  }

  const genExtra = (entry) => (
    <DeleteOutlined
      onClick={event => {
        const currentData = {...data}
        delete currentData[entry.key]
        setData(currentData)
        props.onChange(currentData)
        event.stopPropagation()
      }}
    />
  );

  return (
    <>
      {data && Object.keys(data).length > 0 && <Collapse>
        {Object.keys(data).map((entry, index) => {
          return (
            <Panel header={data[entry][props.headerTitle] || data[entry].key} key={index} extra={genExtra(data[entry])}>
              <Form
                data={data[entry]}
                type="products_osp"
                fields={props.panel}
                pluginSettings={props.pluginSettings}
                onChange={(field, value) => {
                  onFormFieldChange(data[entry].key, field, value)
                }}
                labelCol={4}
              />

              {props.showEditBtn && <Button
                type="primary"
                onClick={() => openModalForValue(data[entry].key, props)}
              >
                Edit
              </Button>}
            </Panel>
          );
        })}
      </Collapse>}
      <Button
        style={{ marginTop: '10px' }}
        icon={<DeliveredProcedureOutlined />}
        type="primary"
        onClick={addItem}
      >
        {props.noEntriesHint || 'Add item'}
      </Button>
    </>
  );


}

export default CMSDynamicOptions;

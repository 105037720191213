import React from "react";
import { Breadcrumb } from "antd";

function NavigationBreadcrumb({ items }) {
  return (
    <Breadcrumb>
      {items.map((item) => (
        <Breadcrumb.Item>{item}</Breadcrumb.Item>
      ))}
    </Breadcrumb>
  );
}

export default NavigationBreadcrumb;

import React from "react";
import { Redirect } from "@reach/router";

import { checkAuthentication } from "./index";

const PrivateRoute = (props) => {
  const Comp = props.as;

  return checkAuthentication() ? (
    <Comp {...props} />
  ) : (
    <Redirect noThrow to="/login" />
  );
};

export default PrivateRoute;

import React, { useEffect, useState } from "react";
import { Link, navigate } from "@reach/router";
import { Button } from "antd";
import { connect } from "react-redux";

import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { Field } from "../fields";
import { Header, Table } from "../components";
import { ContentApi, UserApi } from "../api";
import { asyncForEach, openNotification } from "../utils";

function ListItems({ type, config }) {
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [opLoading, setOpLoading] = useState(loading);
  const [selectedRows, setSelectedRows] = useState([]);
  useEffect(() => {
    fetchData();
  }, [type]);

  const collection = config.users;

  if (!collection.summary)
    throw `Missing summary in config.yml for ${collection.name}`;

  // this manages only arrays, not as netlify
  const columns = collection.summary.map((colName) => {
    const field = collection.fields.find((field) => field.name === colName);
    console.log(field);
    if (!field)
      throw `Summary in config.yml for ${collection.name} contains a field (${colName}) that is not available in the list of fields of the collection`;
    return {
      title: field.label,
      dataIndex: colName,
      render: (text, record) => {
        if (colName === collection.identifier_field) {
          return (
            <Link key={field.name} to={`/users/${record.id}`}>
              <Field data={text} field={field} />
            </Link>
          );
        } else return <Field data={text} field={field} key={field.name} />;
      },
      sorter: (a, b) => (a[field.name] > b[field.name] ? 1 : -1), // here the function must be based also on the type of the field
    };
  });

  const fetchData = async () => {
    setLoading(true);
    const data = (await UserApi.getUsers()).map((entry) => ({
      id: entry.id,
      data: entry,
    }));
    console.log(data);
    setItems(data);
    setLoading(false);
  };

  const deleteRows = async () => {
    setOpLoading(true);
    try {
      await asyncForEach(selectedRows, async (row) => {
        await UserApi.deleteUser(row);
      });
      openNotification(
        "success",
        `Entry deleted`,
        "Your content has been deleted"
      );
    } catch (err) {
      openNotification(
        "error",
        `Can't delete content`,
        "I was unable to delete your content"
      );
    }
    await fetchData();
    setOpLoading(false);
  };

  return (
    <>
      <Header title={collection.label} itemsCount={items.length} type={type}>
        <Button
          danger
          icon={<DeleteOutlined />}
          onClick={deleteRows}
          loading={opLoading}
        >
          Delete
        </Button>
        <Button
          icon={<PlusOutlined />}
          type="primary"
          onClick={() => navigate(`/users/new`)}
        >
          Create new
        </Button>
      </Header>
      <Table
        items={items}
        type={type}
        columns={columns}
        loading={loading}
        onSelectRows={setSelectedRows}
      />
    </>
  );
}

const mapStateToProps = (state) => ({
  config: state.config,
});

export default connect(mapStateToProps, null)(ListItems);

import React from "react";
import { Input } from "antd";

const { TextArea } = Input;

function CMSTextArea(props) {
  const onChange = (e) => {
    props.onChange(e.target.value);
  };

  if (props.onChange) {
    return (
      <TextArea
        rows={4}
        disabled={props.disabled}
        value={props.value}
        onChange={onChange}
      />
    );
  } else return props.value || "";
}

export default CMSTextArea;

import React, { useState, useEffect } from "react";
import { Upload, message, Button } from "antd";
import { UploadOutlined } from "@ant-design/icons";

import { MediaApi } from "../api";

function getError(option, xhr) {
  const msg = `cannot ${option.method} ${option.action} ${xhr.status}'`;
  const err = new Error(msg);
  err.status = xhr.status;
  err.method = option.method;
  err.url = option.action;
  return err;
}

function getBody(xhr) {
  const text = xhr.responseText || xhr.response;
  if (!text) {
    return text;
  }

  try {
    return JSON.parse(text);
  } catch (e) {
    return text;
  }
}

function upload(option) {
  const xhr = new XMLHttpRequest();

  if (option.onProgress && xhr.upload) {
    xhr.upload.onprogress = function progress(e) {
      if (e.total > 0) {
        e.percent = (e.loaded / e.total) * 100;
      }
      option.onProgress(e);
    };
  }

  xhr.onerror = function error(e) {
    option.onError(e);
  };

  xhr.onload = function onload() {
    if (xhr.status < 200 || xhr.status >= 300) {
      return option.onError(getError(option, xhr), getBody(xhr));
    }

    option.onSuccess(getBody(xhr), xhr);
  };

  xhr.open(option.method, option.action, true);

  // Has to be after `.open()`. See https://github.com/enyo/dropzone/issues/179
  if (option.withCredentials && "withCredentials" in xhr) {
    xhr.withCredentials = true;
  }

  const headers = option.headers || {};

  for (const h in headers) {
    if (headers.hasOwnProperty(h) && headers[h] !== null) {
      xhr.setRequestHeader(h, headers[h]);
    }
  }
  xhr.send(option.file);

  return {
    abort() {
      xhr.abort();
    },
  };
}

function Image({ allowedTypes, allowedFiles, fileSizeLimit, value, disabled, onChange }) {
  const [fileList, setFileList] = useState([]);
  const [files, setFiles] = useState([]);
  const [fileType, setFileType] = useState();
  const [fileKey, setFileKey] = useState();
  const [signedUrl, setSignedUrl] = useState("");

  useEffect(() => {
    const defaultFileList =
      value && Array.isArray(value)
        ? value.map((valueEntry) => {
            return {
              uid: valueEntry.uid,
              key: valueEntry.key,
              name: valueEntry.name,
              status: "done",
            };
          })
        : [];

    setFileList(defaultFileList);
    setFiles(defaultFileList)
  }, [value]);

  const beforeUpload = async (file) => {
    if (fileList.length >= allowedFiles) {
      message.error(`There is a limit of ${allowedFiles} files`);
      return false;
    }

    const isFileTypeAllowed = allowedTypes.includes(file.type);

    if (!isFileTypeAllowed) {
      message.error(`You can only upload ${allowedTypes.join(",")} file!`);
    }
    const respectsFileSizeLimit = file.size / 1024 / 1024 < fileSizeLimit;
    if (!respectsFileSizeLimit) {
      message.error(`Image must smaller than ${fileSizeLimit}MB!`);
    }

    if (isFileTypeAllowed && respectsFileSizeLimit) {
      const key = `${new Date().getTime()}_${Math.random()
        .toString(36)
        .substring(7)}`;
      const signedUrlRes = await MediaApi.getSignedUrl(file.type, key);
      setFileKey(key);
      setFileType(file.type);
      setSignedUrl(signedUrlRes.url);
      return true;
    } else return false;
  };

  const handleChange = (info) => {
    if (info.fileList.length > allowedFiles) {
      return;
    }

    const newFiles = [
      ...files,
      {
        name: info.file.name,
        uid: info.file.uid,
        key: fileKey,
        status: info.file.status,
      },
    ].filter((entry) => entry.status === "done" && entry.status !== 'removed');

    if (info.fileList.length !== fileList.length) {
      onChange(newFiles);
    }
    setFileList(info.fileList);
    setFiles(newFiles)

    if (info.file.status === "done") {
      message.success(`${info.file.name} file uploaded successfully`);
      onChange(newFiles);
      MediaApi.onFileUploaded({
        key: fileKey,
        data: {
          name: info.file.name,
          size: info.file.size,
          type: info.file.type,
        },
      });
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  return (
    <Upload
      disabled={disabled}
      name="file"
      action={signedUrl}
      method="PUT"
      customRequest={upload}
      headers={{
        "x-amz-acl": "public-read",
        "Content-Type": fileType,
      }}
      beforeUpload={beforeUpload}
      onChange={handleChange}
      fileList={fileList}
    >
      <Button>
        <UploadOutlined /> Click to Upload
      </Button>
    </Upload>
  );
}

export default Image;

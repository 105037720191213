import fetchAPI from "../fetchAPI";
const baseUrl = process.env.REACT_APP_API_URL;

const getSignedUrl = async (type, key) => {
  const url = `${baseUrl}/media/getSignedUrl?type=${type}&key=${key}`;
  const res = await fetchAPI(url);
  const data = await res.json();
  return data;
};

const listItems = async () => {
  const url = `${baseUrl}/media/list`;
  const res = await fetchAPI(url);
  const data = await res.json();
  return data;
};

const deleteObject = async (media) => {
  const url = `${baseUrl}/media/${media.key}?id=${media.id}&provider=${media.provider}`;
  const res = await fetchAPI(url, {
    method: "DELETE",
  });
  const data = await res.json();

  return data;
};

const onFileUploaded = async (item) => {
  console.log(item)
  const url = `${baseUrl}/media`;
  const res = await fetchAPI(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(item),
  });
  const data = await res.json();

  return data;
};

const getData = async (id) => {
  const url = `${baseUrl}/media/${id}/data`;
  const res = await fetchAPI(url);
  const data = await res.json();
  return data;
}

const updateData = async (id, data) => {
  const url = `${baseUrl}/media/${id}`;
  const res = await fetchAPI(url, {
    method: "PUT", headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data)
  });
  const result = await res.json();
  return result;
}

export { getSignedUrl, listItems, deleteObject, onFileUploaded, getData, updateData };

import React from "react";
import { Switch } from "antd";

function onChange(checked) {
  console.log(`switch to ${checked}`);
}

function CMSSwitch(props) {
  if (props.onChange) {
    return <Switch disabled={props.disabled} checked={props.value} onChange={props.onChange} />;
  } else {
    return <Switch disabled checked={props.value} />;
  }
}

export default CMSSwitch;

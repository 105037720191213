import { useEffect } from "react";
import { navigate } from "@reach/router";
import { removeAuthentication } from "../utils";

function Logout() {
  useEffect(() => {
    removeAuthentication();
    navigate("/login");
  }, []);
  return null;
}

export default Logout;

import React, { useEffect, useState } from "react";
import { Link, navigate } from "@reach/router";
import { Button, Input } from "antd";
import { connect } from "react-redux";
import moment from "moment";

import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { Field } from "../fields";
import { Header, Table } from "../components";
import { ContentApi } from "../api";
import { asyncForEach, openNotification } from "../utils";

const { Search } = Input;

function ListItems({ type, status, config }) {
  const [items, setItems] = useState([]);
  const [filteredItems, setFilteredItems] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [opLoading, setOpLoading] = useState(loading);
  const [selectedRows, setSelectedRows] = useState([]);
  useEffect(() => {
    fetchData();
  }, [type, status]);

  const collection = config.collections.find(
    (collection) => collection.name === type
  );

  if (!collection.summary)
    throw `Missing summary in config.yml for ${collection.name}`;

  // this manages only arrays, not as netlify
  const columns = collection.summary.map((colName) => {
    const field = collection.fields.find((field) => field.name === colName);
    if (!field)
      throw `Summary in config.yml for ${collection.name} contains a field (${colName}) that is not available in the list of fields of the collection`;
    return {
      title: field.label,
      dataIndex: colName,
      render: (text, record) => {
        if (colName === collection.identifier_field) {
          return (
            <Link key={field.name} to={`/create/content/${type}/${record.key}`}>
              <Field data={text || "SENZA TITOLO"} field={field} />
            </Link>
          );
        } else return <Field data={text} field={field} key={field.name} />;
      },
      sorter: (a, b) => {
        if (field.widget === "datetime") {
          return moment(a[field.name]).isAfter(b[field.name]) ? 1 : -1;
        } else return a[field.name] > b[field.name] ? 1 : -1;
      },
    };
  });

  const fetchData = async () => {
    try {
      setLoading(true);
      const data = await ContentApi.getContent(type, status, columns.map(col => `data->${col.dataIndex}`));
      setItems(data.map(d => ({data: d, id: d.id})));
    } catch (err) {
      openNotification(
        "error",
        `Can't fetch content`,
        "Check network settings"
      );
    }
    setLoading(false);
  };

  const deleteRows = async () => {
    setOpLoading(true);
    try {
      await asyncForEach(selectedRows, async (row) => {
        await ContentApi.deleteContentEntry(type, row);
      });
      openNotification(
        "success",
        `Entry deleted`,
        "Your content has been deleted"
      );
    } catch (err) {
      openNotification(
        "error",
        `Can't delete content`,
        "I was unable to delete your content"
      );
    }
    await fetchData();
    setOpLoading(false);
  };

  const searchItems = (value) => {
    if (!value) setFilteredItems(undefined);
    const filteredItems = items.filter((item) => {
      const valueInColumns = columns.map((col) => {
        return (
          item.data[col.dataIndex] &&
          typeof item.data[col.dataIndex] === "string" &&
          item.data[col.dataIndex].toUpperCase().includes(value.toUpperCase())
        );
      });

      return valueInColumns.some((value) => value === true);
    });
    setFilteredItems(filteredItems);
  };

  return (
    <>
      <Header title={collection.label} itemsCount={items.length} type={type}>
        <Search
          placeholder="Search..."
          onSearch={searchItems}
          style={{ width: 200 }}
        />
        <Button
          danger
          icon={<DeleteOutlined />}
          onClick={deleteRows}
          loading={opLoading}
        >
          Delete
        </Button>
        <Button
          icon={<PlusOutlined />}
          type="primary"
          onClick={() => navigate(`/create/content/${type}/new`)}
        >
          Create new
        </Button>
      </Header>
      <Table
        items={filteredItems || items}
        type={type}
        columns={columns}
        loading={loading}
        onSelectRows={setSelectedRows}
      />
    </>
  );
}

const mapStateToProps = (state) => ({
  config: state.config,
});

export default connect(mapStateToProps, null)(ListItems);
